<template>
  <div>
    <v-row class="justify-center pt-8 pb-8">
      {{ $localize('calculator') }}
    </v-row>
    <v-container>
      <v-row class="justify-center">
        <v-col cols="6" md="2">
          <v-text-field v-model="creditSum"
                        v-mask="digitMask"
                        :rules="[rules.required]"
                        :label= "$localize('credit_sum')"
                        clearable
                        dense
          ></v-text-field>
        </v-col>
        <v-col cols="6" md="2">
          <v-text-field v-model="perCent"
                        type="number"
                        :rules="[rules.required]"
                        :label= "$localize('per_cent')"
                        class="input-number-without-spin-button"
                        clearable
                        dense
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="3" md="2">
          <v-text-field v-model="yearPeriod"
                        v-mask="digitMask"
                        :rules="[rules.required]"
                        :label= "$localize('period_year')"
                        clearable
                        dense
          ></v-text-field>
        </v-col>
        <v-col cols="6" sm="3" md="2">
          <v-text-field v-model="reducedPeriod"
                        v-mask="digitMask"
                        :rules="[rules.required]"
                        :label= "$localize('reduced_period')"
                        clearable
                        dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3" md="2">
          <v-row class="justify-center justify-md-start justify-sm-start ma-0 pa-0">
            <v-btn text
                   color="success"
                   class="ma-0 pa-0"
                   :disabled="!isFormFilled"
                   @click="calculate"
            >
              {{ $localize('calculate') }}
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-divider/>
      <v-row v-if="!monthPeriod" class="justify-center pt-8 pb-8">{{ $localize('enter_initial_data') }}</v-row>
      <v-simple-table v-else
                      fixed-header
                      dense
                      mobile-breakpoint="0"
                      style="height: calc(100vh - 210px)"
                      class="table-with-borders elevation-5"
      >
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center" :colspan="6">
              Аннуитетные платежи
            </th>
            <th class="text-center" :colspan="6">
              Платежи равными долями
            </th>
          </tr>
          <tr>
            <th></th>
            <th class="text-center">мес.</th>
            <th class="text-center">ОД</th>
            <th class="text-center">%</th>
            <th class="text-center">Пл. ОД</th>
            <th class="text-center">Платеж</th>
            <th></th>
            <th class="text-center">ОД</th>
            <th class="text-center">%</th>
            <th class="text-center">Пл. ОД</th>
            <th class="text-center">Платеж</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="( row, rowIdx) in monthPeriod">
            <tr :key="rowIdx">
              <td class="text-center">{{rowIdx % 12 === 0 ? (rowIdx / 12) + 1 : ''}}</td>
              <td class="text-center">{{ rowIdx + 1 }}</td>
              <td class="text-end">{{ $moneyFormat(annuitentlianPaymentItems[rowIdx].od) }}</td>
              <td class="text-end">{{ $moneyFormat(annuitentlianPaymentItems[rowIdx].perCent)}}</td>
              <td class="text-end">{{ $moneyFormat(annuitentlianPaymentItems[rowIdx].plOd)}}</td>
              <td class="text-end">{{ $moneyFormat(annuitentlianPaymentItems[rowIdx].payment)}}</td>
              <td></td>
              <td class="text-end">{{ $moneyFormat(equallyPaymentItems[rowIdx].od)}}</td>
              <td class="text-end">{{ $moneyFormat(equallyPaymentItems[rowIdx].perCent)}}</td>
              <td class="text-end">{{ $moneyFormat(equallyPaymentItems[rowIdx].plOd)}}</td>
              <td class="text-end">{{ $moneyFormat(equallyPaymentItems[rowIdx].payment)}}</td>
            </tr>
          </template>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>
  </div>
</template>

<script>
export default{
  name: "CalculatorComponent",
  data() {
    return {
      creditSum: undefined,
      perCent: undefined,
      yearPeriod: undefined,
      reducedPeriod: undefined,
      monthPeriod: undefined,

      monthlyPayment: undefined,
      constB: undefined,

      annuitentlianPaymentItems: [],
      equallyPaymentItems: [],

      rules: {
        required: value => !!value || this.$localize('mandatory_field')
      },
    }
  },

  computed:{
    isFormFilled(){
      if(!this.creditSum) return false
      else if(!this.perCent) return false
      else if(!this.yearPeriod) return false
      else if(!this.reducedPeriod) return false
      return true
    }
  },

  methods:{
    digitMask(value){
      if(value) return [value.replace(/\D/g, '')]
    },

    async calculate(){
      this.annuitentlianPaymentItems = []
      this.equallyPaymentItems = []

      this.monthPeriod = Number(this.yearPeriod) * 12
      let a = this.monthPeriod - 1
      let b = Number(this.perCent) / 1200
      this.constB = b

      //=(A9/(1-(1+A9)^(-A7+B4-1)))
      let c = b / (1 - (1 + b) ** ((a *= -1) + Number(this.reducedPeriod) - 1))
      //=ЕСЛИ(B5>0;(A10/(1-(1+A10)^(-A8+B5-1)));A10/(1-(1+A10)^(-A8)))
      // let d = this.monthPeriod > 0 ?  (c / (1 - (1 + c) ** ((b *= -1) + Number(this.monthPeriod) -1 ))) : ( c / 1 - ( 1 + c) ** (b *= -1))
      this.monthlyPayment = c * Number(this.creditSum)

      for (let i = 0; i < this.monthPeriod; i++) {
        let item = {
          od: undefined,
          perCent: undefined,
          plOd: undefined,
          payment: undefined,
        }
        if(i === 0 || i <= this.reducedPeriod) item.od = this.creditSum
        else item.od = (this.annuitentlianPaymentItems[i-1].od - this.annuitentlianPaymentItems[i-1].plOd).toFixed(2)

        item.perCent = (item.od * this.constB).toFixed(2)
        item.payment = i < this.reducedPeriod ? item.perCent : this.monthlyPayment
        item.plOd = i < this.reducedPeriod ? 0 : (this.monthlyPayment - item.perCent).toFixed(2)
        this.annuitentlianPaymentItems.push(item)
      }

      const olOdAfterReducedPeriod = (this.creditSum/ (this.monthPeriod - this.reducedPeriod)).toFixed(2)
      for (let i = 0; i < this.monthPeriod; i++) {
        let item = {
          od: undefined,
          perCent: undefined,
          plOd: undefined,
          payment: undefined,
        }
        if(i === 0 || i <= this.reducedPeriod) item.od = this.creditSum
        else item.od = (this.equallyPaymentItems[i-1].od - this.equallyPaymentItems[i-1].plOd).toFixed(2)

        item.perCent = (item.od * this.constB).toFixed(2)
        item.plOd = i < this.reducedPeriod ? 0 : olOdAfterReducedPeriod
        item.payment = i < this.reducedPeriod ? item.perCent : (Number(item.perCent) + Number(item.plOd)).toFixed(2)
        this.equallyPaymentItems.push(item)
      }
    },
  }
}
</script>
